export default {
  "error_1": "Неверный запрос, во время которого что-то пошло не так.",
  "error_2": "Попытка доступа без прохождения идентификации личности.",
  "error_3": "Доступ запрещен даже после аутентификации.",
  "error_4": "Запрашиваемый ресурс не найден.",
  "error_5": "Метод запроса не поддерживается для запрашиваемого ресурса.",
  "error_6": "Запрос не может быть обработан из-за несоответствующих заголовков.",
  "error_7": "Запрос приводит к неправильной работе приложения.",
  "error_8": "Ресурс, на который ссылается запрос, больше не доступен.",
  "error_9": "Недействительные учетные данные авторизации.",
  "error_10": "Превышен лимит частоты запросов.",
  "error_11": "Внутренняя ошибка сервера.",
  "error_12": "Запрошенный функционал не поддерживается.",
  "error_13": "Ошибка на стороне шлюза.",
  "error_14": "Сервис недоступен.",
  "error_15": "Шлюз не отвечает в установленное время.",

  "notifications_empty": "Список уведомлений пуст",

  
  "button_activate": "Активировать",
  "button_connect": "Подключить",
  "button_more": "Просмотреть ещё",
  "no_data": "Нет данных",
  "label_sms": "По СМС",
  "account_exit": "Выйти из аккаунта",
  "button_request": "Оставить заявку",
  "coming_soon": "В скором времени функционал будет доступен!",
  "error_unknown": "Возникла непредвиденная ошибка",


  "title_home": "Jet - Личный кабинет",
  "title_login": "Jet - Авторизация",
  "title_access": "Jet - Доверительный доступ",
  "title_loyalty": "Jet - Программа лояльности",
  "title_loyalty_offer": "Jet - Оферта программы лояльности",
  "title_tariffs": "Jet - Тарифы",
  "title_payment": "Jet - Оплата",
  "title_profile": "Jet - Профиль",
  "title_404": "Jet - Страница не найдена",

  "validation": {
    "required": "Поле обязательно для заполнения",
    "number": "Значение должно быть числом",
    "min": "Значение не может быть меньше {min} цифр",
    "min_phone": "Номер телефона не может быть меньше {min} цифр",
    "min_pass": "Пароль должен быть не менее {min} символов",
    "min_sum": "минимальная сумма - {value}",
    "word_pass": "Пароль должен содержать хотя бы одну букву",
    "number_pass": "Пароль должен содержать хотя бы одну цифру",
    "spec_pass": "Пароль должен содержать хотя бы один специальный символ. Пример: !@#$%^&",
    "same_pass": "Пароли должны совпадать",
    "attempts" :"Вы слишком часто пытаетесь войти в систему. Попробуйте позже"
  },

  "settings_title": "Управление аккаунтом",
  "settings_form_sms_title": "Управление",
  "settings_form_sms_descr": "Уважаемый абонент! При желании, Вы можете своевременно получать информацию об оставшемся количестве трафика на Вашем балансе, а также всегда быть в курсе тех. работ на сайте и новостей компании.",
  "settings_form_sms_accept_txt": "Активируя услугу Вы соглашаетесь с",
  "settings_form_sms_accept_link": "правилами и условиями",
  "settings_form_sms_disabled": "Временно недоступно",
  
  "form_change_pass_title": "Изменение пароля",
  "form_change_pass_old_label": "Ваш пароль",
  "form_change_pass_new_label": "Новый пароль",
  "form_change_pass_new_confirm_label": "Повторите новый пароль",
  "form_change_captcha_label": "Введите капча с изображения",
  "form_change_button": "Изменить пароль",
  

  "form_payment_descr_click": "Введите сумму (минимальная сумма платежа 1000 сум) и нажмите кнопку \"Перейти к оплате\". Далее Вы будете перенаправлены на страницу оплаты {0}.",
  "form_payment_descr_payme": "Введите сумму (минимальная сумма платежа 1000 сум) и нажмите кнопку \"Перейти к оплате\". Далее Вы будете перенаправлены на страницу оплаты {0}.",
  "form_payment_descr_upay": "Введите сумму (минимальная сумма платежа 1000 сум) и нажмите кнопку \"Перейти к оплате\". Далее Вы будете перенаправлены на страницу оплаты {0}.",
  "form_payment_descr_card": "Введите сумму и нажмите кнопку \"Перейти к оплате\". Далее Вы будете перенаправлены на страницу оплаты.",
  "form_payment_pay_helper": "Ввести сумму абонентской платы",
  "form_payment_pay_button": "Перейти к оплате",

  "login_title": "Войдите в ваш аккаунт",
  "login_email": "E-mail",
  "login_name": "Логин",
  "login_pass": "Пароль",
  "login_sign-in": "Войти",

  "footer_copy_1": "{copy} 2023 LLC “East - Telecom”, {year}.",
  "footer_copy_2": "Все права защищены.",

  "nav_home": "Главная",
  "nav_history": "История оплат",
  "nav_tariffs": "Тарифы",
  "nav_payment": "Оплата",
  "nav_settings": "Профиль",

  "menu_toggle": "Открыть главное меню",

  "error_title": "Страница не найдена",
  "error_text": "К сожалению, мы не смогли найти страницу, которую вы ищете.",
  "error_button": "На главную",

  "alert_close": "Закрыть",
  "alert_success": "Успешно!",
  "alert_error": "Ошибка!",

  "contract_reg_number": "Договор №: {reg_number}",
  "contract_reg_date": "от {reg_date}",
  "contract_reg_login": "логин: {login}",
  "contract_service_login": "Логин",
  "contract_count_label": "Счет по договору:",
  "contract_count": "UZS {count} ",
  "contract_balance_label": "Баланс",
  "contract_jet_point_label": "Jet Points",
  "contract_jet_rec_pay": "Рекомендованный платёж",
  "contract_button_label": "Пополнить",


  "abonent_label": "Абонент",
  "abonent_address": "Адрес",
  
  "page_group_services_title": "Услуги",
  "page_group_tariff_title": "Тарифы",
  "page_group_jet_tariff_title": "Эксклюзивные тарифы по услуге «Top Secret»",
  "page_group_tariffsTurbo_title": "Заказ услуги «Турбо кнопка»",
  "page_group_title_sub": {
    "part_1": "Доступ только по",
    "part_2": "программе лояльности",
    "part_3": "за Jet Points.",
  },

  "page_group_loyalty_title": "Доступная программа лояльности",
  "page_group_loyalty_descr": "Копите Jet поинты и получите больше от вашего провайдера",

  "sidebar_feedback_title": "Возникли проблемы со связью?",
  "sidebar_feedback_descr": "Если у вас возникли проблемы со связью, пожалуйста, не стесняйтесь и обращайтесь к нам!",
  "sidebar_feedback_button": "Связаться с нами",

  "card_service_title_inet": "Интернет",
  "card_service_status": "Активна",
  "card_service_status_off": "Не активна",
  "card_service_abon_pay": "{abon_pay} {currency}/мес",
  "card_service_trust_access": "Доверительный доступ",
  "card_service_tariff": "{tariff} от {begin_date}",
  "card_service_control": "Управление тарифом",
  
  "card_loyalty_speed": "Скорость",
  "card_loyalty_time": "Время использования",
  "card_loyalty_time_hours": "{time} часов",
  "card_loyalty_jet_cost": "Стоимость в Jet поинтах",
  "card_loyalty_status_off": "Не активна",
  "card_loyalty_button": "Активировать",
  
  
  "card_service_sidebar_inet": "Интернет",
  "card_service_sidebar_tariff": "Тариф: {tariff}",
  "card_service_sidebar_abon_pay": "{abon_pay} {currency}/мес",

  "page_group_service_title": "Смена тарифа",

  "card_tariff_name": "Название",
  "card_tariff_cost": "Стоимость",
  "card_tariff_speed": "Скорость",
  "card_tariff_speed_value": "{value} Мбит/с",
  "card_tariff_cost_uzs_jp": "Стоимость (UZS / JP)",
  "card_tariff_cost_jp": "Стоимость JP",

  "page_group_services_empty": "Нет подключенных услуг",


  "page_group_history_title": "Детализация",
  "page_group_history_sort_label": "Диапозон:",
  "history_date": "Нет данных",
  "history_sum": "Сумма",
  "history_oper": "Операция",
  "history_descr": "Описание",
  "history_no_data": "Нет данных",

  "trusted_form_title": "При активации услуги «Доверительный доступ» абонент полностью соглашается с вышеописанными условиями публичной оферты",
  "trusted_form_pass_label": "Пароль от персонального кабинета",
  "trusted_content_title": "Доверительный доступ",
  "trusted_content_sub_title": "Правила пользования услугой «Доверительный доступ»:",
  "trusted_content_txt": "Услуга \"Доверительный доступ\" позволяет активировать доступ к интернету на сутки без произведения оплаты за услуги пользования сетью Интернет.",
  "trusted_content_txt_1": "Использование услуги возможно только один раз первого числа месяца после 02:00 ночи;",
  "trusted_content_txt_2": "Срок действия услуги - с 1 по 5 число месяца (с момента активации);",
  "trusted_content_txt_3": "После активации услуги рекомендуется перезагрузить PPPoE сессию;",
  "trusted_content_txt_4": "На лимитированных тарифных планах, при условии, что абонент полностью использует предоплаченный трафик по тарифному плану, начнется использование трафика по сверхлимиту;", 
  "trusted_content_txt_5": "Для того, чтобы воспользоваться услугой, долг абонента не должен превышать суммы одной абонентской платы за прошлый месяц;",
  "trusted_content_txt_6": "Во время активации услуги, абонент получает доступ к сети интернет согласно тарифному плану абонента. ",
  "trusted_content_txt_7": "Момент приобретения услуги \"Доверительный доступ\" является безоговорочным акцептом абонента на оплату полной абонентской платы за новый (следующий) месяц;",
  "trusted_content_txt_8": "При условии, если абонент первого числа нового месяца активирует услугу \"Доверительный доступ\" и воспользуется доступом к сети интернет, а затем подаст заявление на расторжение услуг с компанией или на активацию услуги \"Резерв порта\", абонентская плата за новый месяц должна быть оплачена в полном объеме.",

  "modal_turbo_title": "Заказ интернет-пакета",
  "modal_tariff_title": "Смена тарифа",

  "payment_title": "Пополнение счёта"
}