export default {
  "error_1": "Noto'g'ri so'rov, unda nimadir noto'g'ri ketdi.",
  "error_2": "Shaxsiy guvohnomasiz kirishga urinish.",
  "error_3": "Autentifikatsiyadan keyin ham kirish taqiqlangan.",
  "error_4": "So'ralgan resurs topilmadi.",
  "error_5": "So'ralgan resurs uchun so'rov usuli qo'llab-quvvatlanmaydi.",
  "error_6": "So'rov, mos kelmaydigan sarlavhalar tufayli qayta ishlanolmaydi.",
  "error_7": "So'rov, ilovada to'qnashuvga olib keladi.",
  "error_8": "So'rov havolasida ko'rsatilgan resurs endi mavjud emas.",
  "error_9": "Noto'g'ri autentifikatsiya hisob ma'lumotlari.",
  "error_10": "So'rovlar ketma-ketligi chegarasidan oshib ketish.",
  "error_11": "Server ichki xatosi.",
  "error_12": "So'ralgan funktsional qo'llab-quvvatlanmaydi.",
  "error_13": "Gateway xatosi.",
  "error_14": "Xizmat mavjud emas.",
  "error_15": "Gateway belgilangan vaqtda javob bermaydi.",

  "notifications_empty": "Bildirishnomalar yo'q",

  "button_activate": "Faollashtirish",
  "button_connect": "Ulanish",
  "button_more": "Ko'proq ko'rish",
  "no_data": "Ma'lumot yo'q",
  "label_sms": "SMS orqali",
  "account_exit": "Akkauntdan chiqish",
  "button_request": "Arizangizni yuboring",
  "coming_soon": "Funktsionallik tez orada taqdim etiladi!",
  "error_unknown": "Kutilmagan xatolik yuz berdi",


  "title_home": "Jet - Shaxsiy Kabinet",
  "title_login": "Jet - Avtorizatsiya",
  "title_access": "Jet - Ishonchli Kirish",
  "title_loyalty": "Jet - Sadoqat Dasturi",
  "title_loyalty_offer": "Jet - Sadoqat Dasturi Taklifi",
  "title_tariffs": "Jet - Tariflar",
  "title_payment": "Jet - To'lov",
  "title_profile": "Jet - Profil",
  "title_404": "Jet - Sahifa Topilmadi",

  "validation": {
    "required": "Maydon to'ldirilishi shart",
    "number": "Qiymat raqam bo'lishi kerak",
    "min": "Qiymat {min} raqamdan kam bo'lishi mumkin emas",
    "min_phone": "Telefon raqami {min} raqamdan kam bo'lishi mumkin emas",
    "min_pass": "Parol kamida {min} belgidan iborat bo'lishi kerak",
    "min_sum": "minimal summa - {value}",
    "word_pass": "Parol kamida bitta harf o'z ichiga olishi kerak",
    "number_pass": "Parol kamida bitta raqam o'z ichiga olishi kerak",
    "spec_pass": "Parol kamida bitta maxsus belgi o'z ichiga olishi kerak. Misol: !@#$%^&",
    "same_pass": "Parollar mos kelishi kerak",
    "attempts": "Tizimga kirishga juda ko'p urinishlar qilindingiz. Keyinroq harakat qilib ko'ring"
  },

  "settings_title": "Hisobni boshqarish",
  "settings_form_sms_title": "Boshqaruv",
  "settings_form_sms_descr": "Hurmatli abonent! Xohishingizga kora, siz o'z balansingizda qolgan trafik miqdori haqida ma'lumot olishingiz, shuningdek, kompaniyaning yangiliklari va texnik ishlaridan doimo xabardor bo'lishingiz mumkin.",
  "settings_form_sms_accept_txt": "Xizmatni faollashtirish orqali Siz",
  "settings_form_sms_accept_link": "qoida va shartlarni",
  "settings_form_sms_disabled": "Vaqtincha mavjud emas",

  "form_change_pass_title": "Parolni o'zgartirish",
  "form_change_pass_old_label": "Sizning parolingiz",
  "form_change_pass_new_label": "Yangi parol",
  "form_change_pass_new_confirm_label": "Yangi parolni takrorlang",
  "form_change_captcha_label": "Rasmdagi kaptchani kiriting",
  "form_change_button": "Parolni o'zgartirish",

  "form_payment_descr_click": "Summani kiriting (minimal to'lov miqdori 1000 so'm) va \"To'lovga o'tish\" tugmasini bosing. Keyin siz to'lov sahifasiga yo'naltirilasiz {0}.",
  "form_payment_descr_payme": "Summani kiriting (minimal to'lov miqdori 1000 so'm) va \"To'lovga o'tish\" tugmasini bosing. Keyin siz to'lov sahifasiga yo'naltirilasiz {0}.",
  "form_payment_descr_upay": "Summani kiriting (minimal to'lov miqdori 1000 so'm) va \"To'lovga o'tish\" tugmasini bosing. Keyin siz to'lov sahifasiga yo'naltirilasiz {0}.",
  "form_payment_descr_card": "Summani kiriting va \"To'lovga o'tish\" tugmasini bosing. Keyin siz to'lov sahifasiga yo'naltirilasiz.",
  "form_payment_pay_helper": "Obunachilik to'lovini kiritish",
  "form_payment_pay_button": "To'lovga o'tish",

  "login_title": "Hisobingizga kirish",
  "login_email": "E-mail",
  "login_name": "Login",
  "login_pass": "Parol",
  "login_sign-in": "Kirish",

  "footer_copy_1": "{copy} 2023 LLC “East - Telecom”, {year}.",
  "footer_copy_2": "Barcha huquqlar himoyalangan.",

  "nav_home": "Bosh sahifa",
  "nav_history": "To'lovlarning tarixi",
  "nav_tariffs": "Tariflar",
  "nav_payment": "To'lov",
  "nav_settings": "Profil",

  "menu_toggle": "Asosiy menyuni ochish",

  "error_title": "Sahifa topilmadi",
  "error_text": "Afsuski, siz qidirayotgan sahifani topa olmadik.",
  "error_button": "Bosh sahifaga",

  "alert_close": "Yopish",
  "alert_success": "Muvaffaqiyatli!",
  "alert_error": "Xato!",

  "contract_reg_number": "Shartnoma №: {reg_number}",
  "contract_reg_date": "dan {reg_date}",
  "contract_reg_login": "login: {login}",
  "contract_service_login": "Login",
  "contract_count_label": "Shartnoma bo'yicha hisob:",
  "contract_count": "UZS {count} ",
  "contract_balance_label": "Balans",
  "contract_jet_point_label": "Jet Points",
  "contract_jet_rec_pay": "Tavsiya etilgan to'lov",
  "contract_button_label": "To'ldirish",


  "abonent_label": "Abonent",
  "abonent_address": "Manzil",

  "page_group_services_title": "Xizmatlar",
  "page_group_tariff_title": "Tariflar",
  "page_group_jet_tariff_title": "\"Top Secret\" xizmati bo'yicha tariflar",
  "page_group_tariffsTurbo_title": "\"Turbo tugmasi\" xizmatini buyurtma qilish",
  "page_group_title_sub": {
    "part_1": "Faollik faqat",
    "part_2": "sodiqlik dasturi",
    "part_3": "uchun Jet Points.",
  },

  "page_group_loyalty_title": "Mavjud sodiqlik dasturi",
  "page_group_loyalty_descr": "Jet pointlarni to'plang va provayderingizdan ko'proq xizmatlarni ulang",

  "sidebar_feedback_title": "Aloqa bilan bog'liq muammolar paydo bo'ldimi?",
  "sidebar_feedback_descr": "Agar sizda aloqa bilan bog'liq muammolar paydo bo'lsa, iltimos, biz bilan bog'lanishdan uy!",
  "sidebar_feedback_button": "Biz bilan bog'lanish",

  "card_service_title_inet": "Internet",
  "card_service_status": "Faol",
  "card_service_status_off": "Faol emas",
  "card_service_abon_pay": "{abon_pay} {currency}/oy",
  "card_service_trust_access": "Ishonchli kirish",
  "card_service_tariff": "{tariff} dan {begin_date}",
  "card_service_control": "Tarifni boshqarish",

  "card_loyalty_speed": "Tezlik",
  "card_loyalty_time": "Foydalanish vaqti",
  "card_loyalty_time_hours": "{time} soat",
  "card_loyalty_jet_cost": "Jet pointlarda narxi",
  "card_loyalty_status_off": "Faol emas",
  "card_loyalty_button": "Faollashtirish",


  "card_service_sidebar_inet": "Internet",
  "card_service_sidebar_tariff": "Tarif: {tariff}",
  "card_service_sidebar_abon_pay": "{abon_pay} {currency}/oy",

  "page_group_service_title": "Tarifni o'zgartirish",

  "card_tariff_name": "Nomlanishi",
  "card_tariff_cost": "Narxi",
  "card_tariff_speed": "Tezlik",
  "card_tariff_speed_value": "{value} Mbit/s",
  "card_tariff_cost_uzs_jp": "Narxi (UZS / JP)",
  "card_tariff_cost_jp": "JP narxi",

  "page_group_services_empty": "Ulangan xizmatlar yo'q",


  "page_group_history_title": "Batafsil",
  "page_group_history_sort_label": "Diapazon:",
  "history_date": "Ma'lumot yo'q",
  "history_sum": "Summa",
  "history_oper": "Operatsiya",
  "history_descr": "Tavsif",
  "history_no_data": "Ma'lumot yo'q",

  "trusted_form_title": "\"Ishonchli kirish\" xizmatini faollashtirishda, amalga oshirilgan harakatlar abonent tomonidan yuqorida tavsiflangan barcha shart va qoidalarni to'liq qabul qilish hisoblanadi.",
  "trusted_form_pass_label": "Shaxsiy kabinetdan parol",
  "trusted_content_title": "Ishonchli kirish",
  "trusted_content_sub_title": "«Ishonchli kirish» xizmatidan foydalanish qoidalari:",
  "trusted_content_txt": "«Ishonchli kirish» xizmati Internet xizmatlarini to‘lashni amalga oshirmasdan, bir kun davomida Internetga kirishni faollashtirish imkonini beradi.",
  "trusted_content_txt_1": "Xizmatdan foydalanish oyning birinchi sanasida faqat bir marta, soat 02:00dan keyin mumkin;",
  "trusted_content_txt_2": "Xizmat muddati - oyning 1-sanadan 5-sanagacha (faollashtirilgan paytdan boshlab);",
  "trusted_content_txt_3": "Xizmatni faollashtirgandan so‘ng, PPPoE sessiyasini qayta yuklash tavsiya etiladi;",
  "trusted_content_txt_4": "Cheklangan tarif rejalari bo‘yicha, agar abonent tarif rejasiga muvofiq to‘liq oldindan to‘langan trafikdan foydalansa, cheklovdan tashqari trafikdan foydalanish boshlanadi;",
  "trusted_content_txt_5": "Xizmatdan foydalanish uchun abonentning qarzi o‘tgan oy uchun bir abonent to‘lovidan oshmasligi kerak;",
  "trusted_content_txt_6": "Xizmatni faollashtirish vaqtida abonent tarif rejasiga muvofiq Internet tarmog‘iga kirish huquqiga ega bo‘ladi.",
  "trusted_content_txt_7": "\"Ishonchli kirish\" xizmatini sotib olish lahzasi abonent tomonidan yangi oy uchun to‘liq abonent to‘lovini to‘lashga shartsiz rozilik hisoblanadi;",
  "trusted_content_txt_8": "Agar abonent yangi oyning birinchi sanasida \"Ishonchli kirish\" xizmatini faollashtirsa va Internet tarmog‘iga kirishdan foydalansa, so‘ngra kompaniya bilan xizmatni bekor qilish yoki \"Portni rezerv qilish\" xizmatini faollashtirish to‘g‘risida ariza bersa, yangi oy uchun abonent to‘lovi to‘liq miqdorda to‘lanishi kerak.",

  "modal_turbo_title": "Internet-paketga buyurtma bering",
  "modal_tariff_title": "Tarifni o'zgartirish",

  "payment_title": "Hisobni to'ldirish"
}